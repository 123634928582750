/*
Product: Helium Bootstrap UI Kit
Author: UIdeck
Website: https://uideck.com
More Info: https://uideck.com/products/helium-ui-kit
*/
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700|Montserrat:700");
/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: #444;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  margin-top: 0;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

a {
  color: #EB586F;
  -moz-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  letter-spacing: 0px;
  outline: 0 !important;
}

a:hover, a:active, a:focus {
  color: #D14E63;
  text-decoration: none;
}

p {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #444;
  letter-spacing: 0px;
  margin-bottom: 10px;
}

ul, li {
  list-style: none;
  padding: 0;
}

ul, ol {
  list-style: none;
  padding: 0;
}

.orderlist li {
  list-style: block;
  margin-left: 15px;
}

.unorderedlist li {
  list-style: inside;
}

.list-featured {
  list-style: none;
  padding: 0;
}

.list-featured li {
  position: relative;
  font-size: 14px;
  padding-left: 14px;
  margin-bottom: 7px;
}

.list-featured li:before {
  position: absolute;
  display: block;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 0;
  top: 7px;
  background-color: #EB586F;
}

.line-height-36 {
  line-height: 36px;
}

ol li {
  margin-bottom: 7px;
}

li {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
}

blockquote {
  border-left: 0px solid #ddd;
}

blockquote p {
  font-size: 16px;
  font-weight: 400;
}

.blockquote-reverse {
  border-right: none;
}

strong {
  font-weight: 700;
}

.lead {
  font-size: 16px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-default {
  color: #EB586F;
}

.bg-default {
  color: #444;
  background: #EB586F;
  padding: 3px 4px;
}

.bg-primary {
  color: #fff;
  background: #EB586F;
  padding: 3px 4px;
}

.bg-success {
  color: #fff;
  background: #87c056;
  padding: 3px 4px;
}

.bg-info {
  color: #fff;
  background: #4da5e2;
  padding: 3px 4px;
}

.bg-warning {
  color: #fff;
  background: #ffcc66;
  padding: 3px 4px;
}

.bg-danger {
  color: #fff;
  background: #ec7160;
  padding: 3px 4px;
}

/* / End Typography */
.demo_img {
  background: #f7f7f7;
  margin-bottom: 70px;
  margin-left: 1%;
  margin-right: 1%;
  padding-bottom: 20px;
  text-align: center;
}

.demo_img img {
  margin-bottom: 25px;
  max-width: 100%;
  position: relative;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.demo_img img:hover {
  opacity: 0.7;
}

.demo_img h3.demo-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Headers */
.wrapper {
  background: #fff;
  padding-bottom: 60px;
}

.mtb-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

#content {
  padding-bottom: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.space-right {
  margin-right: 25px;
}

.row-spacer {
  margin-bottom: 80px;
}

.page-header-title {
  padding-top: 60px;
  padding-bottom: 60px;
}

.page-header-title .heading-title {
  color: #444;
  font-size: 36px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 400;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

#headers .navbar {
  margin-bottom: 0;
}

#headers .header-caption {
  text-align: center;
  height: auto;
  text-shadow: none !important;
  top: 0;
  bottom: 0;
  padding: 0;
}

#headers .header-content {
  padding: 90px 0;
}

.header-title .text-primary {
  color: #EB586F !important;
}

.header-content header-title span {
  margin-bottom: 15px;
}

.header-content .header-text {
  margin-top: 15px;
  font-size: 14px;
  line-height: 30px;
}

.header-content .btn {
  margin: 15px;
}

/* Header Style 1*/
#header-style-1 {
  /* background-image: url("../img/bg/hero-1-bg.jpg"); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: contain;
}

#header-style-1 .header-title {
  color: #fff;
}

#header-style-1 .header-text {
  color: #fff;
  line-height: 1.5;
}

#header-style-1 .content {
  min-height: 555px;
  height: auto;
}

#header-style-1 p.caption {
  padding: 6px;
  color: #444;
}

/* Header Style 2*/
.header-style-2 {
  background: #f4f6f9;
  padding-top: 80px;
}

.hero-content-v2 {
  margin-top: 144px;
}

.hero-content-v2 h3 {
  font-size: 24px;
  color: #2f3542;
  font-weight: 400;
}

.hero-content-v2 h3 strong {
  display: block;
  font-size: 48px;
  font-weight: 400;
  line-height: 62px;
  margin-bottom: 25px;
}

.hero-content-v2 p {
  color: #444;
  font-size: 18px;
  margin-bottom: 45px;
}

/* Header Style 3*/
.header-style-3 {
  /* background: url("../img/bg/hero-3-bg.jpg") no-repeat left center; */
  background-size: cover;
  padding-top: 80px;
}

.header-style-3 .hero-content-v2 h3 {
  color: #fff;
}

.header-style-3 .hero-content-v2 p {
  color: #fff;
}

/* End Headers */
.section {
  padding: 30px 0;
}

/* Footer */
.footer-info {
  padding: 25px 0;
}

footer .social a {
  font-size: 14px;
  margin-left: 10px;
}

footer {
  background: #282828;
  color: #7C7C7C;
}

footer .small-title {
  margin-bottom: 20px;
  font-size: 22px;
  color: #fff;
}

footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

footer ul li {
  line-height: 25px;
}

footer ul li a {
  color: #999;
  font-size: 14px;
}

footer .menu {
  color: #fff;
}

footer .plain-flicker-gallery {
  margin-right: -5px;
  margin-left: -5px;
}

footer .plain-flicker-gallery a {
  display: inline-block;
  width: 32%;
  font-size: 0px;
  padding: 5px;
}

footer .plain-flicker-gallery a img {
  width: 100%;
}

footer .contact-us .form-control {
  color: #999;
  font-size: 14px;
  margin-bottom: 15px;
  background: #1D1D1D;
  border-radius: 0px;
}

footer #copyright {
  background: #1D1D1D;
}

footer #copyright .row {
  padding: 10px 0;
}

footer #copyright .copyright-text,
footer #copyright .nav-inline {
  line-height: 38px;
  margin-bottom: 0;
}

.social-footer a i {
  font-size: 14px;
  height: 35px;
  text-align: center;
  display: inline-block;
  border: 1px solid #EB586F;
  line-height: 35px;
  margin: 4px;
  width: 35px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.social-footer .fa-facebook:hover {
  background: #3b5998;
  color: #fff;
  border-color: #3b5998;
}

.social-footer .fa-twitter:hover {
  background: #55acee;
  color: #fff;
  border-color: #55acee;
}

.social-footer .fa-linkedin:hover {
  background: #007bb6;
  color: #fff;
  border-color: #007bb6;
}

.social-footer .fa-google-plus:hover {
  background: #dd4b39;
  color: #fff;
  border-color: #dd4b39;
}

.default-footer {
  background: #232935;
  padding-bottom: 100px;
  padding-top: 100px;
}

.copy-right {
  font-size: 12px;
  color: #bac0cb;
}

.dis-blk {
  display: block;
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.footer-logo {
  width: auto;
}

.light-footer {
  background: #f7f7f7;
}

.light-footer p {
  color: #444;
}

.dark-footer {
  background: #222;
}

.dark-footer p {
  color: #ccc;
}

/* Footer Widgets */
.light-footer .widget-area {
  background: #fff;
}

.dark-footer .widget-area {
  background: #000;
}

.color-footer .widget-area {
  background: #D14E63;
}

footer .widget-title {
  text-align: left;
  margin-bottom: 30px;
}

.dark-footer .widget-title h4 {
  color: #fff;
}

.color-footer .widget-title h4 {
  color: #fff;
}

/* ==========================================================================
   Footer Style 2
   ========================================================================== */
footer .block-title {
  font-size: 20px;
  margin-bottom: 25px;
}

footer .footer-Content {
  background-color: #18191b;
  padding: 60px 0;
  color: #fff;
}

.footer-widget p {
  color: #fff;
}

.textwidget {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #fff;
}

footer .menu {
  padding-left: 0;
}

footer .menu li {
  padding-bottom: 10px;
}

footer .menu li a {
  color: #fff;
}

footer .menu li a:hover {
  color: #D14E63;
}

.twitter-content ul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.twitter-content span {
  float: left;
  width: 224px;
}

.twitter-content span a {
  color: #EB586F;
}

.featured-list {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.featured-list li {
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 7px;
  margin-bottom: 7px;
  overflow: hidden;
  position: relative;
}

.bottom-social-icons a {
  color: #fff;
  text-align: center;
  width: 32px;
  line-height: 32px;
  border-radius: 50px;
  border: 1px solid #fff;
  display: inline-block;
  height: 32px;
  margin-left: 5px;
  position: relative;
  overflow: hidden;
}

#copyright {
  background-color: #1d1e20;
  padding: 20px 0 10px;
  color: #fff;
}

#copyright p {
  line-height: 34px;
  color: #999;
}

#copyright p a {
  color: #fff;
}

#copyright p a:hover {
  color: #D14E63;
}

.social-icon .facebook:hover {
  background-color: #3b5998;
  border-color: #3b5998;
}

.social-icon .twitter:hover {
  background-color: #55acee;
  border-color: #55acee;
}

.social-icon .dribble:hover {
  background-color: #D34836;
  border-color: #D34836;
}

.social-icon .flickr:hover {
  background-color: #ff0084;
  border-color: #ff0084;
}

.social-icon .youtube:hover {
  background-color: #CC181E;
  border-color: #CC181E;
}

.social-icon .google-plus:hover {
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.social-icon .linkedin:hover {
  background-color: #007bb5;
  border-color: #007bb5;
}

.subscribe-box {
  margin-top: 18px;
}

.subscribe-box input[type="text"] {
  outline: none;
  color: #444;
  font-size: 12px;
  padding: 6px 12px;
  border: none;
  background: #fff;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  outline: none;
}

.subscribe-box input[type="submit"] {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  background: #EB586F;
  text-transform: uppercase;
  border: none;
  padding: 7px 16px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

/* Buttons */
.space-bottom {
  margin-bottom: 15px;
}

.btn {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0px;
  -moz-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  border-radius: 0;
  padding: 9px 10px;
}

.btn span {
  margin-left: 10px;
  margin-right: 10px;
}

.btn:hover {
  background: transparent;
  cursor: pointer;
}

.btn-xl {
  font-size: 20px;
  padding: 9px 10px;
}

.btn-lg {
  font-size: 15px;
  padding: 10px 32px;
}

.btn-sm {
  font-size: 12px;
  padding: 7px 6px;
}

.btn-xs {
  font-size: 10px;
  padding: 5px 8px;
}

.btn-rounded {
  border-radius: 4px !important;
}

.btn-pill {
  border-radius: 50px !important;
}

.btn-link {
  color: #EB586F;
}

.btn-link:hover {
  color: #D14E63;
  text-decoration: none;
}

.btn-link:focus {
  color: #D14E63;
  text-decoration: none;
}

/* Default Button */
.btn-common, .btn-common:active, .btn-common:focus {
  color: #EB586F;
  border: 2px solid #EB586F;
  background: transparent;
}

.btn-common:hover {
  color: #fff;
  background: #EB586F;
  border: 2px solid #EB586F;
}

.btn-border {
  border: 2px solid #fff;
  color: #fff;
}

.btn-border:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  border-color: #ffffff;
}

.btn-radius {
  border-radius: 30px;
}

.btn-filled {
  background: #EB586F;
  border: 2px solid #EB586F;
  color: #fff;
}

.btn-filled:hover {
  background: #D14E63;
}

.btn-default-filled, .btn-default-filled:active, .btn-default-filled:focus {
  color: #fff;
  background: #EB586F;
  border: 2px solid #EB586F;
}

.btn-default-filled:hover {
  color: #fff;
  background: #D14E63;
  border: 2px solid #D14E63;
}

/* / End Default Button */
/* Success Button */
.btn-success, .btn-success:active, .btn-success:focus {
  color: #87c056;
  border: 2px solid #87c056;
  background: transparent;
}

.btn-success:hover {
  color: #fff;
  background: #87c056;
  border: 2px solid #87c056;
}

.btn-success-filled, .btn-success-filled:active, .btn-success-filled:focus {
  color: #fff;
  background: #87c056;
  border: 2px solid #87c056;
}

.btn-success-filled:hover {
  color: #fff;
  background: #74a44a;
  border: 2px solid #74a44a;
}

/* End Success Button */
/* Info Button */
.btn-info, .btn-info:active, .btn-info:focus {
  color: #4da5e2;
  border: 2px solid #4da5e2;
  background: transparent;
}

.btn-info:hover {
  color: #fff;
  background: #4da5e2;
  border: 2px solid #4da5e2;
}

.btn-info-filled, .btn-info-filled:active, .btn-info-filled:focus {
  color: #fff;
  background: #4da5e2;
  border: 2px solid #4da5e2;
}

.btn-info-filled:hover {
  color: #fff;
  background: #428fc4;
  border: 2px solid #428fc4;
}

/* End Info Button */
/* Warning Button */
.btn-warning, .btn-warning:active, .btn-warning:focus {
  color: #ffcc66;
  border: 2px solid #ffcc66;
  background: transparent;
}

.btn-warning:hover {
  color: #fff;
  background: #ffcc66;
  border: 2px solid #ffcc66;
}

.btn-warning-filled, .btn-warning-filled:active, .btn-warning-filled:focus {
  color: #fff;
  background: #ffcc66;
  border: 2px solid #ffcc66;
}

.btn-warning-filled:hover {
  color: #fff;
  background: #e1b459;
  border: 2px solid #e1b459;
}

/* End Warning Button */
/* Danger Button */
.btn-danger, .btn-danger:active, .btn-danger:focus {
  color: #ec7160;
  border: 2px solid #ec7160;
  background: transparent;
}

.btn-danger:hover {
  color: #fff;
  background: #ec7160;
  border: 2px solid #ec7160;
}

.btn-danger-filled, .btn-danger-filled:active, .btn-danger-filled:focus {
  color: #fff;
  background: #ec7160;
  border: 2px solid #ec7160;
}

.btn-danger-filled:hover {
  color: #fff;
  background: #cd6254;
  border: 2px solid #cd6254;
}

/* End Danger Button */
/* Paginations */
.pagination .btn {
  font-size: 14px;
  padding: 4px 10px;
  margin: 0 5px;
}

.pagination .btn-direction {
  font-size: 12px;
  padding: 5.5px 8px;
}

.pagination .btn-default {
  color: #EB586F;
  border: 1px solid #ddd;
  background: transparent;
}

.pagination .btn-default:hover {
  color: #fff;
  background: #EB586F;
  border: 1px solid #EB586F;
}

.pagination .btn-active {
  color: #fff !important;
  background: #EB586F !important;
  border: 1px solid #EB586F !important;
}

.pagination .btn-default-filled {
  color: #EB586F;
  background: #ddd;
  border: 1px solid #ddd;
}

.pagination .btn-default-filled:hover {
  color: #fff;
  background: #D14E63;
  border: 1px solid #D14E63;
}

/* End Paginations */
/*  Standard Buttons */
.button-title {
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  opacity: 0.2;
  text-align: left;
}

.button-title span {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.btn {
  font-size: 14px;
  padding: 11px 27px;
}

.btn:focus {
  outline: none;
}

.std-btn {
  margin-bottom: 20px;
}

.icon-cloud-download {
  margin-right: 13px;
  font-size: 16px;
  vertical-align: -2px;
}

.btn-primary {
  margin-right: 20px;
  border: none;
}

.learn-more .btn {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2.33px;
}

.read-more .btn {
  font-weight: 600;
  min-width: 125px;
}

/* Small Buttons */
.btn-sm {
  font-size: 12px;
  min-width: 122px;
  padding: 5px 11px;
}

.learn-more .btn.btn-sm {
  font-size: 11px;
}

.read-more .btn.btn-sm {
  min-width: 94px;
}

/* large Buttons */
.btn-lg {
  font-size: 18px;
  min-width: 198px;
  padding: 15px 11px;
}

.learn-more .btn-lg {
  font-size: 17px;
}

.learn-more2 .btn-lg {
  min-width: 170px;
}

/*Extra large Buttons */
.btn-xlg {
  font-size: 22px;
  min-width: 233px;
  padding: 22px 27px;
  font-weight: 600;
}

.learn-more .btn-xlg {
  font-size: 19px;
}

.read-more .btn-xlg {
  min-width: 186px;
  font-size: 21px;
}

/* Navigations */
.bg-faded {
  background-color: transparent;
}

.bg-default {
  background: #F8F8F8;
  border: none;
}

.bg-default .navbar-nav .nav-link {
  color: #444;
}

.bg-black {
  background-color: #333;
}

.bg-black .navbar-nav .nav-link {
  color: #fff;
}

.navbar-fixed-top {
  border-radius: 0;
}

.navbar-brand {
  color: #ccc !important;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  /* padding: 20px 15px; */
}

.navbar-brand img {
  max-width: 200px;
}

.navbar-light .navbar-brand, .navbar-light .navbar-toggler {
  color: #EB586F !important;
}

.navbar-toggle .icon-bar {
  background: #EB586F;
  width: 20px;
  height: 3px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.navbar-collapse {
  text-align: center;
}

.navbar {
  padding: 0;
}

.dropdown-menu {
  padding: 10px 0px;
  border: 0;
  border-radius: 0;
}

.dropdown-item {
  padding: 10px 22px;
  font-size: 14px;
  width: 170px;
}

.dropdown-item:focus, .dropdown-item:hover {
  background: #EB586F;
  color: #fff;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #EB586F;
}

/* Default Navbar */
.navbar-nav .nav-link {
  color: #fff;
  font-size: 14px;
  margin-right: 18px;
  font-weight: 400;
  padding: 18px 0px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #EB586F;
}

.navbar-light .navbar-nav .nav-link:hover:focus {
  color: #EB586F;
}

.navbar-light .navbar-nav > .active > a, .navbar-light .navbar-nav > .active > a:focus, .navbar-light .navbar-nav > .active > a:hover {
  color: #EB586F;
  background-color: transparent;
}

.navbar-light .navbar-nav > .open > a, .navbar-light .navbar-nav > .open > a:focus, .navbar-light .navbar-nav > .open > a:hover {
  color: #EB586F;
  background-color: transparent;
}

.navbar-light .navbar-toggle .icon-bar {
  background-color: #EB586F;
}

.navbar-light .navbar-toggle {
  border-color: transparent;
}

.navbar-light .navbar-toggle:hover, .navbar-light .navbar-toggle:focus {
  background-color: transparent;
}

/* / End Default Navbar */
.form-inline .form-control {
  margin: 0;
  color: #fff;
  border-radius: 50px;
  min-height: 42px;
  padding: 7px 20px;
  background: transparent;
}

.top_search_icon {
  position: absolute;
  top: 31px;
  right: 44px;
  color: #fff;
}

/* Navbar-1 */
.navbar-1 {
  border-radius: 0;
}

.navbar-1.navbar-light {
  background-color: #f7f7f7;
  border-color: transparent;
}

.navbar-inverse {
  background-color: #222;
  border-color: transparent;
}

/* Transparent Navigations */
.nav-bg-default {
  /* background: url("../images/nav-bg-default.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.nav-bg-inverse {
  /* background: url("../images/nav-bg-inverse.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.nav-bg-default .navbar {
  margin-bottom: 0;
  opacity: 0.8;
}

.nav-bg-inverse .navbar {
  margin-bottom: 0;
  opacity: 0.8;
}

.navbar-light-transparent {
  background-color: transparent !important;
}

.navbar-inverse-transparent {
  background-color: transparent !important;
}

.navbar-light-transparent.navbar-light .navbar-nav > li > a {
  color: #fff;
}

.navbar-light-transparent.navbar-light .navbar-nav > li > a:hover {
  color: #EB586F;
}

.navbar-light-transparent.navbar-light .navbar-nav > li > a:focus {
  color: #fff !important;
}

.navbar-light-transparent.navbar-light .navbar-nav > .active > a, .navbar-light-transparent.navbar-light .navbar-nav > .active > a:focus, .navbar-light-transparent.navbar-light .navbar-nav > .active > a:hover {
  color: #EB586F;
  background-color: transparent;
}

.navbar-light-transparent.navbar-light .navbar-nav > .open > a, .navbar-light-transparent.navbar-light .navbar-nav > .open > a:focus, .navbar-light-transparent.navbar-light .navbar-nav > .open > a:hover {
  color: #EB586F;
  background-color: transparent;
}

.navbar-light-transparent.navbar-light .navbar-toggle .icon-bar {
  background-color: #EB586F;
}

.navbar-light-transparent.navbar-light .navbar-toggle {
  border-color: transparent;
}

.navbar-light-transparent.navbar-light .navbar-toggle:hover, .navbar-light-transparent.navbar-light .navbar-toggle:focus {
  background-color: transparent;
}

.navbar-inverse-transparent.navbar-inverse .navbar-nav > li > a {
  color: #000;
}

.navbar-inverse-transparent.navbar-inverse .navbar-nav > li > a:hover {
  color: #EB586F;
}

.navbar-inverse-transparent.navbar-inverse .navbar-nav > li > a:focus {
  color: #000 !important;
}

.navbar-inverse-transparent.navbar-inverse .navbar-nav > .active > a, .navbar-inverse-transparent.navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse-transparent.navbar-inverse .navbar-nav > .active > a:hover {
  color: #EB586F;
  background-color: transparent;
}

.navbar-inverse-transparent.navbar-inverse .navbar-nav > .open > a, .navbar-inverse-transparent.navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse-transparent.navbar-inverse .navbar-nav > .open > a:hover {
  color: #EB586F;
  background-color: transparent;
}

.navbar-inverse-transparent.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #EB586F;
}

.navbar-inverse-transparent .navbar-inverse .navbar-toggle {
  border-color: transparent;
}

.navbar-inverse-transparent.navbar-inverse .navbar-toggle:hover, .navbar-inverse-transparent.navbar-inverse .navbar-toggle:focus {
  background-color: transparent;
}

.form-inline .form-control {
  margin: 0;
  border-radius: 50px;
  min-height: 42px;
  padding: 7px 20px;
  background: transparent;
}

/* Top Menus */
.top-menu {
  background-color: #333;
}

.top-menu-inverse {
  background-color: #f7f7f7;
}

.top-menu p {
  margin-bottom: 0;
  padding: 9px 15px;
}

.top-menu-bg p {
  padding: 9px 0;
}

.top-menu-bg-inverse p {
  padding: 9px 0;
}

.top-menu a {
  color: #fff !important;
  font-size: 12px !important;
  margin-right: 10px;
  font-weight: 400;
}

.top-menu-inverse a {
  color: #444 !important;
}

.top-menu a:hover {
  color: #EB586F !important;
}

.top-menu .left i {
  margin-right: 5px;
}

.top-menu .right {
  float: right;
}

.top-menu .right a {
  margin: 0 0 0 15px;
}

.top-menu .right a i {
  margin-right: 5px;
}

.account-setting a {
  margin: 0 !important;
  padding: 6px;
}

.account-setting a i {
  margin-right: 5px;
}

.top-menu .container {
  padding: 0 30px;
}

.top-menu-bg a {
  color: #fff !important;
}

.top-menu-bg-inverse a {
  color: #000 !important;
}

.btn-top-menu {
  background: transparent;
  font-size: 10px !important;
  font-weight: 700;
  border: 2px solid #444;
  margin: -3px 0 0 10px;
  padding: 5px 0;
}

.btn-top-menu:hover {
  border: 2px solid #EB586F;
}

.btn-top-menu a {
  font-size: 10px !important;
  margin: 0 !important;
  padding: 8px;
}

.btn-top-menu-inverse {
  background: transparent;
  font-size: 10px !important;
  font-weight: 700;
  border: 2px solid #ccc;
  margin: -3px 0 0 10px;
  padding: 5px 0;
}

.btn-top-menu-inverse:hover {
  border: 2px solid #EB586F;
  background: #EB586F;
}

.btn-top-menu-inverse a:hover {
  color: #fff !important;
}

.btn-top-menu-inverse a {
  font-size: 10px !important;
  margin: 0 !important;
  padding: 8px;
}

.top-menu-bg .btn-top-menu {
  border: 2px solid #fff;
}

.top-menu-bg .btn-top-menu:hover {
  border: 2px solid #EB586F;
}

.top-menu-bg-inverse .btn-top-menu-inverse {
  border: 2px solid #000;
}

.top-menu-bg-inverse .btn-top-menu-inverse:hover {
  border: 2px solid #EB586F;
}

/* / End Top Menus */
.tbtn {
  font-size: 30px;
  height: 42px;
  width: 120px;
  padding: 8px;
  cursor: pointer;
  position: fixed;
  background: #EB586F;
  z-index: 999;
  top: 124px;
  left: 0px;
}

.tbtn p {
  font-size: 11px;
  color: #fff;
}

.tbtn p i {
  margin-right: 4px;
  font-size: 12px;
}

.title-menu {
  font-size: 12px;
  padding: 28px 15px;
  text-transform: uppercase;
}

.navmenu, .navbar-offcanvas {
  width: 200px;
  z-index: 99999;
}

.navmenu-default, .navbar-default .navbar-offcanvas {
  background-color: #fff;
  border-left: 1px solid #eee;
}

.navmenu-default .navmenu-nav > li > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > li > a:hover,
.navmenu-default .navmenu-nav > li > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > li > a:focus {
  color: #EB586F;
  background-color: transparent;
}

.navmenu-default .navmenu-nav > li {
  border-bottom: 1px solid #eee;
  line-height: 24px;
}

.navmenu-default .navmenu-nav > li > a,
.navbar-default .navbar-offcanvas .navmenu-nav > li > a {
  color: #444;
  padding: 0px 15px;
  font-size: 14px;
  font-weight: 300;
}

nav#menu span.fa.fa-bars:hover {
  color: #EB586F;
}

.navmenu-default .navmenu-nav > .active > a,
.navbar-default .navbar-offcanvas .navmenu-nav > .active > a,
.navmenu-default .navmenu-nav > .active > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > .active > a:hover,
.navmenu-default .navmenu-nav > .active > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > .active > a:focus {
  color: #EB586F;
}

.close {
  background: transparent;
  padding: 4px 12px;
  opacity: 1;
  position: absolute;
  top: 14px;
  right: 0px;
}

.close i {
  background: #EB586F;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
}

.social-icon {
  width: 25%;
}

.social-icon a {
  margin-right: 2px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  width: 24px;
  height: 24px;
  background: #777575;
  display: inline-block;
  line-height: 24px;
  border-radius: 50%;
}

#services {
  padding: 30px 0;
}

.service-box {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}

.service-box .service-icon {
  float: left;
  margin-top: 5px;
}
.service-box .service-icon img {
  width: 80px;
}

.service-box .service-icon i {
  font-size: 32px;
  color: #EB586F;
}

.service-box .service-content {
  padding-left: 36px;
}

.service-box .service-content h4 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 700;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

.service-box .service-content h4 a {
  color: #444;
}

.service-box .service-content h4 a:hover {
  color: #EB586F;
}

.service-center .service-icon {
  float: none;
  width: 60px;
  height: 60px;
  margin: 0px auto 20px;
  background: #ddd;
  line-height: 70px;
  border-radius: 4px;
  text-align: center;
}

.service-center .service-content {
  padding-left: 0;
}

.sub-title {
  margin-bottom: 40px;
}

.sub-title {
  color: #999;
  font-size: 24px;
  font-weight: 400;
}

.image-service-box h4 {
  font-weight: 700;
  font-size: 16px;
}

.image-service-box h4 a {
  color: #444;
}

.image-service-box h4 a:hover {
  color: #EB586F;
}

.image-service-box .service-text {
  background: #f5f5f5;
  padding: 15px;
  border: 1px solid #e3e3e3;
}

/* Team Item */
.team-item figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.team-item figure img {
  width: 100%;
  height: auto;
}

.team-item figure figcaption {
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  opacity: 0;
  width: 100%;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team-item figure figcaption .details {
  position: absolute;
  top: 30%;
}

.team-item figure figcaption .content-white {
  font-size: 13px;
  text-align: center;
  color: #FFF;
  margin: 0 auto;
  line-height: 18px;
  width: 65%;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.team-item figure figcaption .small-divider {
  margin: 12px auto 6px;
  width: 0px;
  background: #fff;
  height: 1px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.team-item figure:hover figcaption {
  opacity: 1;
}

.team-item figure:hover .small-divider {
  width: 62px;
  background: #fff;
}

.team-item figure:hover figcaption .content-white {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.team-item figure:hover .social a {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.team-item .social {
  text-align: center;
  margin-top: 15px;
}

.team-item .social .facebook,
.team-item .social .twitter,
.team-item .social .google-plus {
  font-size: 24px;
  line-height: normal;
  outline: none;
  border-radius: 50%;
  color: #fff;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  margin-right: 5px;
}

.team-item .social .facebook {
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.team-item .social .twitter {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-item .social .google-plus {
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}

.team-item .social a:hover {
  background: transparent;
}

.social .fa-facebook:hover {
  color: #3b5998;
}

.social .fa-twitter:hover {
  color: #55acee;
}

.social .fa-instagram:hover {
  color: #fb3958;
}

.info {
  background: #F6F6F6;
  padding: 15px 0 10px;
  text-align: center;
}

.info h2 {
  color: #000;
  font-size: 18px;
}

.info p {
  color: #000;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  text-transform: uppercase;
}

.members {
  text-align: center;
}

.members .memeber-img {
  margin-bottom: 30px;
}

.members .member-content .member-name {
  font-size: 25px;
  line-height: 33px;
  margin: 0;
}

.members .member-content .member-position {
  font-size: 14px;
  margin-top: 10px;
}

/* team members tow */
.team-members-tow figure {
  position: relative;
  cursor: pointer;
}

.team-members-tow figure:hover .image-overlay {
  opacity: 1;
}

.team-members-tow figure:hover .info-text {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.team-members-tow figure:hover .social-icons {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.team-members-tow .image-overlay {
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  opacity: 0;
  width: 100%;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team-members-tow .overlay-text {
  color: #ffffff;
  padding-top: 30%;
}

.team-members-tow .overlay-text .info-text {
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team-members-tow .overlay-text .info-text strong {
  display: block;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 5px;
}

.team-members-tow .overlay-text .info-text span {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}

.team-members-tow .overlay-text .small-divider {
  margin-bottom: 30px;
  border-color: #fff;
  margin-top: 30px;
  width: 50px;
}

.team-members-tow .social-icons {
  padding-left: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team-members-tow .social-icons li {
  display: inline-block;
  margin: 0 10px;
}

.team-members-tow .social-icons li a i {
  font-size: 24px;
  color: #fff;
}

.team-members-tow .social-icons .fa-facebook:hover {
  color: #3b5998;
}

.team-members-tow .social-icons .fa-twitter:hover {
  color: #55acee;
}

.team-members-tow .social-icons .fa-instagram:hover {
  color: #fb3958;
}

.team-members-three img {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
}

.team-members-three .team-content {
  margin-top: 30px;
}

.team-members-three .team-content h3 {
  display: block;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 5px;
}

.team-members-three .team-content span {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #999;
  font-style: italic;
}

.team-members-three .team-content p {
  color: #999;
}

.team-members-three .team-content .info-text {
  margin-bottom: 30px;
}

.team-members-three .social-icons {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team-members-three .social-icons li {
  display: inline-block;
  margin: 0 10px;
}

.team-members-three .social-icons li a i {
  font-size: 24px;
  color: #999;
}

.team-members-three .social-icons .fa-facebook:hover {
  color: #3b5998;
}

.team-members-three .social-icons .fa-twitter:hover {
  color: #55acee;
}

.team-members-three .social-icons .fa-instagram:hover {
  color: #fb3958;
}

/* About */
#about {
  padding: 30px 0;
}

.about h5 {
  font-size: 18px;
}

.about h5 a {
  color: #444;
}

.about h5 a:hover {
  color: #EB586F;
}
/* .about.block .img-box {
  max-height: 75px;
} */

.about.block img {
  max-width: 100px;
  margin-bottom: 15px;
  zoom: 2;
}

.testimonial {
  position: relative;
}

#facts {
  padding: 60px 0;
}

#facts .fact-icon {
  color: #EB586F;
  font-size: 46px;
  display: block;
  margin-top: 0;
  margin-bottom: 20px;
}

.timer {
  color: #444;
  font-size: 48px;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 20px;
}

.fact-title {
  color: #444;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px;
}

#single-testimonial-item .item {
  margin: 10px;
}

#single-testimonial-item .property-main {
  margin-bottom: 0;
}

#single-testimonial-item .owl-controls .owl-buttons {
  position: relative;
  top: -160px;
  left: 0;
}

#single-testimonial-item .owl-controls .owl-buttons div.owl-prev {
  float: left;
  margin-left: -45px;
}

#single-testimonial-item .owl-controls .owl-buttons div.owl-next {
  float: right !important;
  margin-right: -45px;
}

.owl-theme .owl-buttons div {
  width: 40px;
  height: 40px;
  display: block !important;
  background: transparent !important;
  text-align: center;
  display: inline-block;
  opacity: 1 !important;
  border-radius: 0 !important;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

.owl-theme .owl-buttons div:hover {
  opacity: 0.8 !important;
}

.owl-theme .owl-buttons div i {
  color: #fff;
  font-size: 16px;
  line-height: 32px;
}

/* / End About */
/* Start Tesitmonial  */
.single-testimonial-area {
  /* background-image: url(../img/about/testimonial-bg.jpg); */
  background-size: cover;
  padding: 60px 0;
}

.single-testimonial-area .testimonial-inner blockquote {
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.single-testimonial-area .testimonial-inner .testimonial-images {
  text-align: center;
}

.single-testimonial-area .testimonial-inner .testimonial-images img {
  box-shadow: 0 0 3px #ddd;
  height: 90px;
  margin: 0 auto;
  border-radius: 50%;
  padding: 5px;
  width: 90px;
}

.single-testimonial-area .testimonial-inner .testimonial-footer {
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}

.single-testimonial-area .testimonial-inner .testimonial-footer a {
  color: #EB586F;
  font-size: 15px;
}

/* End Tesitmonial  */
/* List Style */
.list-title {
  margin-bottom: 30px;
  font-size: 27px;
}

.list-style li {
  margin-bottom: 15px;
  font-size: 14px;
  position: relative;
}

.list-style li i {
  margin-right: 10px;
  color: #EB586F;
}

/* Features Header */
.features-header {
  background: #f5f5f5;
  margin: 30px 0;
  padding: 30px 30px;
}

.features-wrap {
  background: #f5f5f5;
  padding: 30px 30px 0px;
  margin-bottom: 50px;
}

/* Features Box */
.featured-box {
  margin-bottom: 30px;
}

.featured-box .featured-icon {
  float: left;
}

.featured-box .featured-icon i {
  width: 48px;
  height: 48px;
  color: #EB586F;
  border: 1px solid #EB586F;
  display: inline-block;
  text-align: center;
  line-height: 48px;
  font-size: 22px;
  border-radius: 50%;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.featured-box .featured-content {
  padding-left: 64px;
}

.featured-box .featured-content h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.featured-box:hover .featured-icon i {
  background: #EB586F;
  color: #fff;
}

.featured-center {
  text-align: center;
}

.featured-center .featured-icon {
  float: none;
  margin-bottom: 20px;
}

.featured-center .border-icon i {
  font-size: 30px;
  border: none;
}

.featured-center .featured-content {
  padding-left: 0;
}

.featured-img {
  margin-bottom: 30px;
}

.featured-img .featured-content {
  background: #f5f5f5;
  padding: 15px;
}

.featured-img .featured-content h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.featured-box-item {
  margin-bottom: 40px;
}

.featured-box-item .featured-icon {
  float: left;
}

.featured-box-item .featured-icon i {
  width: 70px;
  height: 70px;
  color: #EB586F;
  border: 2px solid #f1f1f1;
  text-align: center;
  line-height: 70px;
  font-size: 30px;
  border-radius: 10px;
  display: inline-block;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.featured-box-item .featured-content {
  padding-left: 88px;
}

.featured-box-item .featured-content h4 {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.featured-box-item:hover .featured-icon i {
  background: #EB586F;
  color: #fff;
}

/* Pricing Table */
#pricing {
  padding: 30px 0;
}

.pricing-table {
  background: #353b48;
  padding: 30px;
  text-align: center;
}

.pricing-table .table-header h3 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fff;
}

.pricing-table .plan .price {
  font-size: 24px;
  color: #fff;
}

.pricing-table .plan-info {
  margin-top: 30px;
}

.pricing-table .plan-info p {
  color: #fff;
}

.pricing-table .button-area {
  margin-top: 30px;
}

/* Pricing Table */
.pricing-tables {
  padding: 25px 0;
  text-align: center;
}

.pricing-tables .pricing-table-block {
  background: #f6f6f6;
  text-align: center;
  position: relative;
  background-color: #fff;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: 1px solid #f1f1f1;
}

.pricing-tables .highlight-plan {
  margin: -15px 0;
  margin-right: -1px;
  z-index: 1;
  background: #f6f6f6;
}

.pricing-tables .pricing-table-block .highlight-plan .plan-name {
  padding: 25px 0;
}

.pricing-tables .pricing-table-block .highlight-plan .plan-name h3 {
  color: #fff;
}

.pricing-tables .pricing-table-block .highlight-plan .plan-signup {
  padding: 30px 0;
  background: #EB586F;
}

.pricing-tables .pricing-table-block .highlight-plan .plan-signup .btn-system.border-btn {
  border-color: #fff;
  color: #fff;
}

.pricing-table-block {
  background: #f6f6f6;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.pricing-table-block .plan-name {
  padding: 25px;
  background: #EB586F;
  margin-bottom: 20px;
}

.pricing-table-block .plan-name h3 {
  font-size: 22px;
  line-height: 24px;
  margin: 0;
  color: #fff;
}

.pricing-table-block .plan-price {
  margin-bottom: 30px;
}

.pricing-table-block .plan-price .price-value {
  font-size: 50px;
  line-height: 76px;
  font-weight: 700;
  color: #333333;
}

.pricing-table-block .plan-price .price-value span {
  font-size: 22px;
  font-weight: 400;
  line-height: 18px;
}

.pricing-table-block .plan-price .interval {
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
}

.pricing-table-block .plan-list li {
  padding: 10px;
  font-size: 15px;
  letter-spacing: 1px;
  border-bottom: 1px solid #ddd;
}

.pricing-table-block .plan-list li i {
  margin-right: 10px;
}

.pricing-table-block .plan-signup {
  padding: 15px 0px 30px;
}

.pricing-table-block .plan-signup a {
  text-transform: uppercase;
}

.pricing-table-item {
  border-radius: 4px;
  text-align: center;
  position: relative;
  margin: 15px 0;
  box-shadow: 0px 2px 18px 0px rgba(198, 198, 198, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.pricing-table-item .plan-name {
  padding: 25px;
  margin-bottom: 0px;
}

.pricing-table-item .plan-name h3 {
  font-size: 22px;
  line-height: 24px;
  margin: 0;
  color: #333333;
}

.pricing-table-item .plan-price {
  margin-bottom: 30px;
}

.pricing-table-item .plan-price .price-value {
  font-size: 50px;
  line-height: 76px;
  font-weight: 700;
  color: #333333;
}

.pricing-table-item .plan-price .price-value span {
  font-size: 22px;
  font-weight: 400;
  line-height: 18px;
}

.pricing-table-item .plan-price .interval {
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
}

.pricing-table-item .plan-list li {
  padding: 10px;
  font-size: 15px;
  letter-spacing: 1px;
}

.pricing-table-item .plan-list li i {
  margin-right: 10px;
}

.pricing-table-item .plan-signup {
  padding: 15px 0px 30px;
}

.pricing-table-item .plan-signup a {
  text-transform: uppercase;
}

.table-active {
  background: #EB586F;
}

.table-active .plan-name h3 {
  color: #fff;
}

.table-active .plan-price .price-value {
  color: #fff;
}

.table-active .plan-price .price-value span {
  color: #fff;
}

.table-active .plan-price .interval {
  color: #fff;
}

.table-active .plan-list li {
  color: #fff;
}

.table-active .plan-list li i {
  margin-right: 10px;
}

.table-active .plan-signup .btn-common {
  background: #fff;
}

.table-active .plan-signup .btn-common:hover {
  color: #EB586F;
}

/* Cards */
.card {
  position: relative;
  display: block;
  margin-bottom: 30px;
  background-color: #f7f7f7;
  border-radius: 0;
  border: 0;
  width: 100%;
}

.card-img-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.card-img-inside {
  margin-bottom: 15px;
}

.card img {
  width: 100%;
}

.card-block {
  padding: 15px;
}

.card-title {
  color: #444;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 15px;
}

.card-text {
  font-size: 14px;
  color: #444;
}

.card-btn {
  margin-top: 5px;
}

.card-2 {
  position: relative;
  display: block;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
}

.card-2 .card-block .card-title {
  margin-bottom: 7px;
}

.card-2 .card-block .meta {
  font-size: 13px;
}

.card-2 .card-block .meta a {
  color: #999;
}

.card-2 .card-block .meta a:hover {
  color: #EB586F;
  cursor: pointer;
}

.card-2 .card-block .description {
  margin-top: 5px;
}

.card-2 .extra {
  max-width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  width: auto;
  margin: 0;
  padding: 10px 15px;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.4);
}

.card-2 .extra .right {
  float: right;
}

.card-2 .extra .right i {
  margin-right: 5px;
}

.card-2 .card-text {
  font-size: 14px;
  line-height: 1.2;
}

.card-2 .card-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* / End Cards */
/* Blog Section Start */
.blog-item-wrapper {
  box-shadow: 0px 1px 4px #dddddd;
}

.blog-item-wrapper .blog-item-text {
  padding: 20px;
}

.blog-item-wrapper .blog-item-text h3 a {
  color: #444;
  font-size: 18px;
}

.blog-item-wrapper .blog-item-text h3 a:hover {
  color: #EB586F;
}

.blog-item-wrapper .blog-item-text .meta-tags {
  font-size: 12px;
}

.blog-item-wrapper .blog-item-text .meta-tags .comments a {
  color: #444;
  font-size: 12px;
}

.blog-item-wrapper .blog-item-text .meta-tags .comments a:hover {
  color: #EB586F;
}

.blog-block {
  box-shadow: 0px 1px 4px #dddddd;
}

.blog-block .blog-content {
  padding: 15px;
}

.blog-block .blog-content h3 {
  font-size: 18px;
}

.blog-block .blog-content h3 a {
  color: #444;
}

.blog-block .blog-content h3 a:hover {
  color: #EB586F;
}

.blog-post {
  padding: 30px;
}

.blog-post h3 {
  font-size: 18px;
}

.blog-post h3 a {
  color: #444;
}

.blog-post h3 a:hover {
  color: #EB586F;
}

.about-sidebar-widget,
.categories-sidebar-widget,
.blog-posts-widget {
  padding: 15px;
}

.about-sidebar-widget h4,
.categories-sidebar-widget h4,
.blog-posts-widget h4 {
  color: #222;
  padding: 10px 0;
  font-size: 20px;
}

.about-sidebar-widget p,
.categories-sidebar-widget p,
.blog-posts-widget p {
  color: #444;
}

.about-sidebar-widget img {
  margin-bottom: 10px;
}

.categories-sidebar-widget .post-category {
  border-bottom: 1px solid #EFEFEF;
  padding: 3px 0px;
}

.categories-sidebar-widget .post-category a {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
}

.categories-sidebar-widget .post-category:last-child {
  border: none;
}

.blog-post-small {
  margin-bottom: 15px;
  border-bottom: 1px solid #EFEFEF;
}

.blog-post-small img {
  max-width: 66px;
  margin: 0 10px 0 0;
  float: left;
}

.blog-post-small a {
  color: #999;
  font-size: 14px;
}

.blog-post-small p {
  font-size: 12px;
}

.blog-post-small .post-date {
  font-size: 12px;
}

.blog-post-small:last-child {
  border: none;
}

/* Progress Bars */
.progress {
  height: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress .progress-bar {
  font-size: 11px;
  line-height: 15px;
  color: #fff;
  background-color: #EB586F;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar-default {
  background-color: #EB586F;
}

.progress-bar-primary {
  background-color: #EB586F;
}

.progress-bar-success {
  background-color: #87c056;
}

.progress-bar-info {
  background-color: #4da5e2;
}

.progress-bar-warning {
  background-color: #ffcc66;
}

.progress-bar-danger {
  background-color: #ec7160;
}

.progress-label .sr-only {
  position: relative;
}

/*  End Progress Bars */
/* Caounter Style */
#counter {
  /* background-image: url(../img/about/testimonial-bg.jpg); */
  background-size: cover;
  padding: 60px 0;
}

.count-one {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.count-one .icon {
  margin-bottom: 20px;
  display: block;
}

.count-one .icon i {
  color: #EB586F;
  font-size: 48px;
}

.count-one .count-value {
  color: #fff;
  font-size: 48px;
  margin-bottom: 20px;
}

.count-one .count-title {
  color: #fff;
  font-size: 14px;
}

.width25-divider {
  margin: 20px auto;
  width: 25px;
  border-color: #fff;
}

/* Sliders */
/* Dark Slider */
#dark-slider h3 {
  color: #ccc;
}

#dark-slider .slide-text {
  color: #ccc;
  line-height: 1.5;
}

#dark-slider p.caption {
  color: #fff;
  padding: 6px;
}

#dark-slider .item {
  min-height: 555px;
  height: auto;
}

#dark-slider .slide1 {
  /* background-image: url("../img/dark-bg-image.jpg"); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: contain;
}

#dark-slider .slide2 {
  /* background-image: url("../img/dark-bg-image.jpg"); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center;
}

#dark-slider .carousel-control {
  color: #ccc;
  opacity: 0.8;
}

.text-primary {
  color: #EB586F !important;
}

/* Carousels */
.owl-theme .owl-controls .owl-page span {
  width: 12px !important;
  height: 12px !important;
  margin: 5px !important;
  background: #EB586F !important;
}

#cards-carousel .card-block {
  margin: 0 10px;
  background: #f7f7f7;
}

#cards-carousel .card {
  background: transparent;
  margin-bottom: 0;
}

#image-carousel .item {
  margin: 10px;
}

#image-carousel .item img {
  display: block;
  width: 100%;
  height: auto;
}

/* Testimonials Slider */
#testimonials-slider {
  background-color: #ccc;
}

#testimonials-slider .testimonial {
  padding: 150px 0;
  text-align: center;
}

#testimonials-slider .testimonial img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-top: -40px;
  margin-bottom: 40px;
}

#testimonials-slider .testimonial h3 {
  color: #fff;
  font-size: 30px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  max-width: 900px;
}

#testimonials-slider .testimonial i {
  color: #fff;
  font-size: 38px;
  margin-bottom: 40px;
}

.customer-name {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

#testimonials-slider .carousel-indicators {
  margin-bottom: 10px;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  background-color: #fff;
  margin: 3px;
  opacity: 1;
}

.carousel-indicators li {
  width: 12px;
  height: 12px;
  margin: 3px;
  background-color: #fff;
  border: 0;
  opacity: 0.5;
}

/* / Sliders */
#carousel-area {
  background: #2c3e50;
}

#carousel-area .btn-lg {
  margin: 10px;
}
#carousel-area .carousel-inner img {
  /* opacity: 1; */
  zoom: 0.5;
}

#carousel-area .carousel-inner .active {
  opacity: 1;
}

#carousel-area .carousel-inner .active.left,
#carousel-area .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

#carousel-area .carousel-inner .next.left,
#carousel-area .carousel-inner .prev.right {
  opacity: 1;
}

#carousel-area .carousel-control {
  z-index: 2;
}

#carousel-area .carousel-item .carousel-caption {
  top: 28%;
  right: 10%;
  left: 10%;
  text-shadow: none;
}

#carousel-area .carousel-item .carousel-caption h5 {
  color: #fff;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
}

#carousel-area .carousel-item .carousel-caption h3 {
  font-size: 50px;
  margin-bottom: 15px;
  color: #fff;
  font-weight: 700;
}

#carousel-area .carousel-indicators {
  bottom: 5px;
}

#carousel-area .carousel-indicators li {
  border-radius: 0px;
  width: 32px;
  height: 5px;
  margin: 0 5px;
  background: rgba(253, 254, 255, 0.8);
  border: none;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

.carousel-post {
  margin-right: 15px;
}

.carousel-post h5 {
  margin-top: 20px;
}

#single-testimonial-item .item {
  margin: 10px;
}

#single-testimonial-item .property-main {
  margin-bottom: 0;
}

#single-testimonial-item .owl-controls .owl-buttons {
  position: relative;
  top: -160px;
  left: 0;
}

#single-testimonial-item .owl-controls .owl-buttons div.owl-prev {
  float: left;
  margin-left: -45px;
}

#single-testimonial-item .owl-controls .owl-buttons div.owl-next {
  float: right !important;
  margin-right: -45px;
}

#image-slider .owl-controls .owl-buttons {
  position: relative;
  top: -280px;
  left: 0;
}

#image-slider .owl-controls .owl-buttons div.owl-prev {
  float: left;
  margin-left: 45px;
}

#image-slider .owl-controls .owl-buttons div.owl-next {
  float: right !important;
  margin-right: 45px;
}

#posts-carousel-3col .owl-controls .owl-buttons {
  position: relative;
  top: -235px;
  left: 0;
}

#posts-carousel-3col .owl-controls .owl-buttons div.owl-prev {
  float: left;
  margin-left: -60px;
}

#posts-carousel-3col .owl-controls .owl-buttons div.owl-next {
  float: right !important;
  margin-right: -45px;
}

#posts-carousel .owl-controls .owl-buttons {
  position: relative;
  top: -235px;
  left: 0;
}

#posts-carousel .owl-controls .owl-buttons div.owl-prev {
  float: left;
  margin-left: -60px;
}

#posts-carousel .owl-controls .owl-buttons div.owl-next {
  float: right !important;
  margin-right: -45px;
}

/* Forms */
.title-head {
  color: #444;
  font-size: 30px;
  margin-bottom: 30px;
}

.contact-form .form-control {
  background: transparent;
  padding: 15px 25px 15px 50px;
  color: #444;
}

.contact-form .form-control:focus {
  border-color: #EB586F;
}

.contact-form .contact-icon {
  left: 34px;
  position: absolute;
  top: 16px;
  color: #444;
}

.contact-form textarea {
  height: 268px;
  width: 100%;
  background: transparent;
  border: 1px solid #ddd;
  padding: 18px 18px;
  color: #444;
  margin-bottom: 35px;
  resize: vertical;
}

.contact-form textarea:focus {
  border-color: #EB586F;
}

.contact-form .form-group {
  margin-bottom: 0;
}

.contact-info-title {
  color: #444;
  font-size: 24px;
  margin-bottom: 25px;
}

.contact-info a {
  display: block;
  font-size: 14px;
  color: #444;
  margin-bottom: 10px;
  position: relative;
  font-weight: 400;
}

.contact-info a i {
  margin-right: 10px;
}

.contact-info address {
  position: relative;
  margin-bottom: 15px;
}

address {
  font-size: 14px;
  color: #444;
  line-height: 1.8;
}

address i {
  margin-right: 10px;
}

.tel-info {
  margin-bottom: 15px;
}

.social-links li {
  display: inline-block;
}

.social-links li a {
  color: #444;
  font-size: 18px;
  padding-left: 0;
  margin: 10px 20px 0 0;
}

.form-control {
  color: #444;
  background: #f7f7f7;
  font-weight: 400;
  letter-spacing: 0px;
  border: 1px solid #ddd;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 10px 20px;
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control:focus {
  border-color: #EB586F;
}

.form-group input {
  font: normal normal normal 14px/1 FontAwesome, "Rubik", sans-serif;
}

.input-group input {
  font: normal normal normal 14px/1 FontAwesome, "Rubik", sans-serif;
}

/* Contact Forms */
#contactForm .form-control {
  color: #444;
  background: #f7f7f7;
  font-weight: 400;
  letter-spacing: 0px;
  border: 1px solid #ddd;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 10px 20px;
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#contactForm .form-control:focus {
  border-color: #EB586F;
}

#contactForm textarea {
  max-width: 100%;
  min-height: 130px !important;
  max-height: 211px;
  margin-bottom: 30px;
}

::-webkit-input-placeholder {
  color: #ccc !important;
}

:-moz-placeholder {
  color: #ccc !important;
}

::-moz-placeholder {
  color: #ccc !important;
}

:-ms-input-placeholder {
  color: #ccc !important;
}

.has-error .form-control {
  color: #ec7160 !important;
  border: 1px solid #ec7160 !important;
}

.help-block.with-errors li {
  color: #ec7160;
  font-size: 13px;
  margin-top: -30px;
  margin-bottom: 0;
}

#msgSubmit.h3 {
  font-size: 14px;
  margin-top: 5px;
}

/* Contact Form 1 */
#contact-form-1 .form-group {
  margin-bottom: 0;
}

#contact-form-1 #contactForm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#contact-form-1 .btn-form-submit {
  width: 100%;
  border-radius: 0;
  padding: 14px 24px;
}

/* Contact Form 2 */
.contact-form-2 {
  margin-bottom: 50px;
}

#contactForm.contact-form-2 .form-control#name {
  width: 70%;
}

#contactForm.contact-form-2 .form-control#email {
  width: 70%;
}

#contactForm.contact-form-2 .form-control#subject {
  width: 70%;
}

#contact-form-2 .form-group {
  margin-bottom: 0;
}

#contact-form-2 #contactForm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#contact-form-2 .btn-form-submit {
  width: 30%;
  border-radius: 0;
  padding: 14px 24px;
}

/* Login / Register Forms */
#login-form {
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  padding: 35px;
}

#login-form .form-group {
  margin-bottom: 0;
}

#login-form .btn-log {
  border-radius: 0;
  margin-bottom: 30px;
  padding-left: 17px;
  padding-right: 17px;
}

#register-form {
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  padding: 35px;
}

#register-form .form-group {
  margin-bottom: 0;
}

#register-form .btn-log {
  border-radius: 0;
  margin-bottom: 30px;
  padding-left: 17px;
  padding-right: 17px;
}

.log-title {
  font-size: 24px;
  margin: 0 0 35px 0;
}

.log-line {
  padding: 0 0 35px 0;
  margin-bottom: 35px;
}

.log-line .checkbox {
  margin-left: 3px;
}

.log-line .checkbox-primary label {
  color: #EB586F;
}

.forgot-password {
  font-size: 16px;
}

.checkbox .hide {
  display: none;
}

/* / End Login / Register Forms */
#newsletter .input-group {
  margin-bottom: 20px;
}

/* / End Forms */
/* Labels Badges & Alerts */
/* Labels */
.space-25 {
  margin-bottom: 25px;
}

.label {
  font-weight: 400;
  padding: 5px 18px;
  display: inline-block;
  margin: 0px 15px 0px 0px;
}

.label-pill {
  border-radius: 5em;
}

.label-default {
  color: #EB586F;
  background-color: transparent;
  border: 2px solid #EB586F;
}

.label-default-filled {
  color: #fff;
  background-color: #EB586F;
  border: 2px solid #EB586F;
}

.label-primary {
  color: #EB586F;
  background-color: transparent;
  border: 2px solid #EB586F;
}

.label-primary-filled {
  color: #fff;
  background-color: #EB586F;
  border: 2px solid #EB586F;
}

.label-success {
  color: #87c056;
  background-color: transparent;
  border: 2px solid #87c056;
}

.label-success-filled {
  color: #fff;
  background-color: #87c056;
  border: 2px solid #87c056;
}

.label-info {
  color: #4da5e2;
  background-color: transparent;
  border: 2px solid #4da5e2;
}

.label-info-filled {
  color: #fff;
  background-color: #4da5e2;
  border: 2px solid #4da5e2;
}

.label-warning {
  color: #ffcc66;
  background-color: transparent;
  border: 2px solid #ffcc66;
}

.label-warning-filled {
  color: #fff;
  background-color: #ffcc66;
  border: 2px solid #ffcc66;
}

.label-danger {
  color: #ec7160;
  background-color: transparent;
  border: 2px solid #ec7160;
}

.label-danger-filled {
  color: #fff;
  background-color: #ec7160;
  border: 2px solid #ec7160;
}

/* / End Labels */
/* Badges */
.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #EB586F;
}

.nav-pills > li > a:hover {
  color: #fff;
  background-color: #EB586F;
}

.nav-pills > li > a {
  border-radius: 5px;
  padding: 10px;
  margin-right: 20px;
}

.badge {
  color: #fff;
  background-color: #EB586F;
  font-size: 10px;
  font-weight: 400;
  border-radius: 5px;
  padding: 6px 7px;
}

.active .badge {
  color: #fff !important;
}

/* / End Badges */
/* Alerts */
.massage-box {
  position: relative;
  padding: 23px 20px 13px 55px;
  border-radius: 4px;
  margin: 0 0 30px;
}

.massage-box strong {
  font-size: 24px;
  display: block;
  margin: 0 0 10px;
}

.massage-box p {
  color: #fff;
}

.massage-box i {
  position: absolute;
  left: 15px;
  top: 22px;
  width: 25px;
  font-size: 24px;
  text-align: center;
}

.small-box strong, .small-box i {
  font-size: 14px;
}

.alert {
  border: 2px solid transparent;
  border-radius: 5px;
}

.alert-success {
  color: #fff;
  background-color: #87c056;
  border-color: #74a44a;
}

.alert-success .close {
  color: #74a44a;
}

.alert-info {
  color: #fff;
  background-color: #4da5e2;
  border-color: #428fc4;
}

.alert-info .close {
  color: #428fc4;
}

.alert-warning {
  color: #fff;
  background-color: #ffcc66;
  border-color: #e1b459;
}

.alert-warning .close {
  color: #e1b459;
}

.alert-danger {
  color: #fff;
  background-color: #ec7160;
  border-color: #cd6254;
}

.alert-danger .close {
  color: #cd6254;
}

.alert-dismissible .close {
  font-size: 16px;
  top: -14px;
  right: -31px;
  text-shadow: none;
  opacity: 1;
}

.alert-dismissible .close:hover {
  opacity: 0.8;
}

.alert-sm {
  font-size: 14px;
  padding: 5px;
}

.alert i {
  margin: 0 10px 0 5px;
}

.alert-sm.alert-dismissible .close {
  top: -5px;
  right: -2px;
}

.tgl {
  position: relative;
  display: inline-block;
  height: 30px;
  cursor: pointer;
}

.tgl > input {
  position: absolute;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}

.tgl .tgl_body {
  width: 60px;
  height: 30px;
  background: white;
  border: 1px solid #dadde1;
  display: inline-block;
  position: relative;
  border-radius: 50px;
}

.tgl .tgl_switch {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-color: white;
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 50%;
  border: 1px solid #ccd0d6;
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.13);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.13);
  -moz-transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), -moz-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -o-transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), -o-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -webkit-transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), -webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  z-index: 1;
}

.tgl .tgl_track {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 50px;
}

.tgl .tgl_bgd {
  font-size: 11px;
  color: #fff;
  padding: 4px 18px;
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  width: 55px;
  -moz-transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), right 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -o-transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), right 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -webkit-transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), right 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), right 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  background: #EB586F;
}

.tgl .tgl_bgd-negative {
  right: auto;
  left: -45px;
  background: #ddd;
}

.tgl:hover .tgl_switch {
  border-color: #b5bbc3;
  -moz-transform: scale(1.06);
  -ms-transform: scale(1.06);
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
}

.tgl:active .tgl_switch {
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.tgl > :not(:checked) ~ .tgl_body > .tgl_switch {
  left: 30px;
}

.tgl > :not(:checked) ~ .tgl_body .tgl_bgd {
  right: -45px;
}

.tgl > :not(:checked) ~ .tgl_body .tgl_bgd.tgl_bgd-negative {
  right: auto;
  left: -10px;
}

/* / End Alerts */
#portfolio {
  background: #F6F6F6;
  padding: 60px 0;
  width: 100%;
}

#portfolio .mix {
  padding: 0;
  display: none;
}

.controls {
  text-align: center;
  padding: 20px;
}

a:not([href]):not([tabindex]) {
  color: #222;
}

a:focus:not([href]):not([tabindex]), a:hover:not([href]):not([tabindex]) {
  color: #fff;
}

.controls .active {
  color: #fff !important;
  background: #EB586F;
}

#portfolio-list {
  margin-top: 15px;
  display: inline-block;
}

.portfolio-img {
  overflow: hidden;
  display: block;
  position: relative;
}

.portfolio-img img {
  width: 100%;
}

.portfoli-content {
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0;
  top: 0;
  -webkit-transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.portfoli-content:before {
  background-color: RGBA(235, 88, 111, 0.7);
  top: 15px;
  left: 15px;
  bottom: 15px;
  right: 15px;
  content: '';
  position: absolute;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.sup-desc-wrap {
  display: table;
  width: 100%;
  height: 100%;
}

.sup-desc-wrap .sup-title {
  display: block;
  outline: none;
  margin-bottom: 10px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sup-desc-wrap .sup-title h4 {
  font-size: 18px;
  color: #fff;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 300;
}

.sup-desc-wrap .sup-desc-inner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 0 35px;
}

.sup-desc-wrap .sup-link {
  margin-bottom: 20px;
}

.sup-desc-wrap .sup-link a:hover {
  background: #fff;
  color: #EB586F;
}

.sup-desc-wrap .sup-link .left-link,
.sup-desc-wrap .sup-link .right-link {
  font-size: 14px;
  line-height: normal;
  outline: none;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  width: 42px;
  height: 42px;
  opacity: 0;
  line-height: 42px;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  margin-right: 5px;
}

.sup-desc-wrap .sup-link .left-link {
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.sup-desc-wrap .sup-link .right-link {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sup-desc-wrap .sup-meta-wrap .sup-description {
  font-size: 13px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.portfolio-item:hover .portfoli-content,
.portfolio-item:hover .portfoli-content:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.portfolio-item:hover .sup-title {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.portfolio-item:hover .sup-description {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.portfolio-item:hover .sup-link .right-link,
.portfolio-item:hover .sup-link .left-link {
  opacity: 1;
  webkit-transform: translateY(0px);
  transform: translateY(0px);
}

/* Portfolio */
#portfolio ul.portfolio {
  margin-bottom: 0 !important;
}

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.hovereffect img {
  display: block;
  position: relative;
  -webkit-transition: all .5s linear;
  transition: all .2s linear;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: RGBA(235, 88, 111, 0.7);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.hovereffect .iocns {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
}

.hovereffect .iocns .left-link {
  font-size: 14px;
  line-height: normal;
  outline: none;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.hovereffect .iocns a:hover {
  background: #fff;
  color: #EB586F;
}

.hovereffect-title h6,
.hovereffect-title h5 {
  color: #fff;
  background: transparent;
  text-align: center;
  position: absolute;
  top: 40%;
  width: 100%;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 10px;
}

.hovereffect-title h5 {
  color: #fff;
  background: transparent;
  text-align: center;
  position: absolute;
  top: 43%;
  width: 100%;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 10px;
}

.hovereffect-title h4 {
  color: #fff;
  background: transparent;
  text-align: center;
  position: absolute;
  top: 44%;
  width: 100%;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  padding: 10px;
}

.hovereffect:hover img {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
}

.hovereffect:hover h6, .hovereffect:hover a.info {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-transform: translatey(0);
  -webkit-transform: translatey(0);
  transform: translatey(0);
}

.hovereffect:hover a.info {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

figure {
  display: inline-block;
}

#portfolio .portfolio.block {
  top: 15px;
}

#portfolio .portfolio.block h5 {
  color: #444;
}

#portfolio .project {
  padding-top: 15px;
  padding-bottom: 15px;
}

#portfolio .project-description {
  font-size: 14px;
  color: #444;
}

.icons-link {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
}

.icons-link a:hover {
  border: 1px solid  #fff;
  background: transparent;
}

.icons-link .left-link,
.icons-link .right-link {
  font-size: 14px;
  line-height: normal;
  outline: none;
  border-radius: 4px;
  color: #fff;
  background: #EB586F;
  border: 1px solid transparent;
  width: 42px;
  height: 42px;
  opacity: 0;
  line-height: 42px;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  margin-right: 5px;
}

.icons-link .left-link {
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.icons-link .right-link {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.hovereffect:hover .icons-link .right-link,
.hovereffect:hover .icons-link .left-link {
  opacity: 1;
  webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.table-1 {
  color: #fff;
}

.table-bordered.table-2 > thead > tr > th {
  background: #232833;
  font-weight: 700;
  color: #EB586F;
}

.table-bordered.table-3 > thead > tr > th {
  background: #EB586F;
  font-weight: 700;
  color: #fff;
}

.table-bordered > thead > tr > th {
  text-align: center;
  background: #373d4a;
  font-size: 16px;
  font-weight: 700;
  padding: 26px 10px;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #f1f1f1;
}

.table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 18px 20px;
  color: #444;
  text-align: center;
  font-size: 14px;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #e3e3e3;
}

/* Tabs */
#default-tab ul.nav-tabs {
  border-bottom: none;
  padding-bottom: 5px;
}

#default-tab ul.nav-tabs a {
  border: 0;
}

ul.nav-tabs li {
  float: left;
  margin-top: 0;
}

ul.nav-tabs li a {
  color: #444;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
}

#default-tab .nav-tabs > li > a.active, #default-tab .nav-tabs > li a.active:focus, #default-tab .nav-tabs > li a.active:hover {
  border: 0 none;
  position: relative;
  color: #EB586F;
}

.nav-bg > li > a {
  color: #444;
  text-transform: uppercase;
  border: 1px solid transparent;
  margin-right: 10px;
}

.nav-bg > li > a.active, .nav-bg > li a.active:focus, .nav-bg > li a:hover {
  color: #EB586F !important;
  border: 1px solid #EB586F !important;
  border-radius: 0;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #EB586F;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.tab-content {
  padding: 15px;
  border: 1px solid #ddd;
}

/* End TAbs */
/* Accordions */
.panel-group .panel {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 10px;
  border: 0;
  border-radius: 0;
}

.panel-group .panel-heading {
  padding: 15px;
  background: #EB586F;
}

.panel-group .panel-title {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
}

.panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.panel-group .panel-heading > a:after {
  float: right;
}

.panel-group .panel-heading .panel-title.collapsed:after {
  float: right;
}

.panel-group .panel-heading > a:after {
  display: inline-block;
  vertical-align: middle;
  font-family: "FontAwesome";
  font-size: 16px;
  content: "\f0d8";
  margin-top: -2px;
  margin-left: 10px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.panel-group .panel-heading .panel-title.collapsed:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-group .panel-body {
  padding: 15px;
  border: 1px solid #ddd;
}

.panel-group.tabbed .panel-heading {
  background: transparent;
  padding: 15px;
  border: 1px solid #ccc;
}

.panel-group.tabbed a {
  color: #444;
}

.panel-group.tabbed .panel-heading > a:after {
  float: right;
}

.panel-group.tabbed .panel-heading .panel-title.collapsed:after {
  float: right;
}

/* End Tabs & Accordions */
@media (min-width: 768px) and (max-width: 1024px) {
  .fact-title {
    font-size: 14px;
  }
  #single-testimonial-item .owl-controls .owl-buttons div.owl-prev {
    margin-left: 0px;
  }
  #single-testimonial-item .owl-controls .owl-buttons div.owl-next {
    margin-right: 0px;
  }
  .blog-item-wrapper .blog-item-text h3 a {
    font-size: 14px;
  }
  .blog-post-small img {
    max-width: 100%;
  }
  .contact-info-title {
    font-size: 18px;
  }
  .featured-box .featured-content h4 {
    font-size: 13px;
  }
  footer #copyright .copyright-text, footer #copyright .nav-inline {
    line-height: 24px;
  }
  .nav-link {
    padding: 8px 3px;
  }
  .social-footer a .fa {
    margin: 0px;
  }
  .hero-content-v2 {
    margin-top: 0;
  }
  .list-title {
    font-size: 20px;
  }
  .list-style li i {
    margin-right: 5px;
  }
  .sub-title {
    font-size: 14px;
  }
  .bs-example {
    margin-bottom: 30px;
  }
  .bs-example .nav > li > a {
    padding: 10px 19px;
  }
  .pricing-table-block .plan-list li {
    font-size: 12px;
  }
  .pricing-table .table-header h3 {
    font-size: 15px;
  }
  .button-area .btn {
    padding: 11px 12px;
  }
  .service-box .service-content h4 {
    font-size: 14px;
  }
  #carousel-area .carousel-item .carousel-caption h3 {
    font-size: 26px;
  }
  #carousel-area .carousel-item .carousel-caption h5 {
    margin-bottom: 10px;
  }
  #carousel-area .carousel-item .btn-lg {
    padding: 7px 10px;
    min-width: 150px;
  }
  .sup-desc-wrap .sup-meta-wrap .sup-description {
    display: none;
  }
  .label {
    padding: 0;
  }
}

@media (max-width: 640px) {
  .page-header-title .heading-title {
    font-size: 24px;
  }
  .navbar-light .navbar-toggler {
    margin: 24px;
    border: 0;
  }
  .blog-item-wrapper {
    margin-bottom: 30px;
  }
  .blog-item-wrapper .blog-item-text h3 a {
    font-size: 12px;
  }
  .contact-form {
    margin-bottom: 30px;
  }
  .contact-info {
    margin-bottom: 30px;
  }
  .featured-box .featured-content h4 {
    font-size: 14px;
  }
  .footer-info .pull-right {
    float: left !important;
  }
  .footer-widget {
    margin-bottom: 30px;
  }
  .nav-link {
    padding: 8px 3px;
  }
  .hero-content-v2 {
    margin-top: 0;
  }
  .hero-content-v2 h3 {
    font-size: 22px;
  }
  .hero-content-v2 h3 strong {
    font-size: 32px;
    line-height: 38px;
  }
  .store-btn-wrap li {
    margin-bottom: 20px;
  }
  .team-members-tow figure {
    margin-bottom: 30px;
  }
  .bs-example {
    margin-bottom: 30px;
  }
  .bs-example .nav > li > a {
    padding: 10px 19px;
  }
  .tab-content .tabbing-content {
    width: 100%;
    padding-left: 0;
  }
  .pricing-table {
    margin-bottom: 30px;
  }
  .pricing-table-block {
    margin-bottom: 30px;
  }
  .service-box .service-content h4 {
    font-size: 14px;
  }
  #carousel-area .carousel-item .carousel-caption {
    top: 5%;
  }
  #carousel-area .carousel-item .carousel-caption h3 {
    font-size: 26px;
  }
  #carousel-area .carousel-item .carousel-caption h5 {
    margin-bottom: 0px;
  }
  #carousel-area .carousel-item .btn-lg {
    padding: 7px 10px;
    min-width: 150px;
  }
  .controls .btn {
    padding: 7px 14px;
    margin: 5px 0;
  }
  .list-group {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .navbar-brand img {
    max-width: 120px;
  }
  .sub-title {
    font-size: 20px;
  }
  .top-menu {
    height: 70px;
  }
  .top-menu .right {
    float: left;
  }
  .page-header-title .heading-title {
    font-size: 16px;
  }
  .title-head {
    font-size: 20px;
  }
  .about {
    margin-bottom: 30px;
  }
  #single-testimonial-item .owl-controls .owl-buttons {
    display: none;
  }
  .blog-item-wrapper {
    margin-bottom: 30px;
  }
  .contact-form {
    margin-bottom: 30px;
  }
  .contact-info {
    margin-bottom: 30px;
  }
  .contact-info-title {
    font-size: 18px;
  }
  footer ul li a {
    font-size: 12px;
  }
  header h3 {
    font-size: 18px;
  }
  .hero-content-v2 {
    margin-top: 0;
  }
  .hero-content-v2 h3 {
    font-size: 22px;
  }
  .hero-content-v2 h3 strong {
    font-size: 32px;
    line-height: 38px;
  }
  .store-btn-wrap li {
    margin-bottom: 20px;
  }
  .team-members-tow figure {
    margin-bottom: 30px;
  }
  .bs-example {
    margin-bottom: 30px;
  }
  .tab-content .tabbing-content {
    width: 100%;
    margin-top: 10px;
    padding-left: 0;
  }
  .pricing-table {
    margin-bottom: 30px;
  }
  .pricing-table-block {
    margin-bottom: 30px;
  }
  .service-box .service-content h4 {
    font-size: 14px;
  }
  .count-one {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .carousel-inner {
    /* height: 175px; */
  }
  #carousel-area .carousel-item .carousel-caption {
    top: 2%;
  }
  #carousel-area .carousel-item .carousel-caption h3 {
    font-size: 14px;
  }
  #carousel-area .carousel-item .carousel-caption h5 {
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 15px;
  }
  #carousel-area .carousel-item .btn-lg {
    padding: 4px 5px;
    min-width: 150px;
    font-size: 12px;
  }
  .carousel-indicators {
    display: none;
  }
  .controls .btn {
    font-size: 13px;
    padding: 7px 8px;
    margin: 5px 0;
  }
  .list-group {
    margin-bottom: 30px;
  }
}

/* Custom CSS */
body {
  color: #444;
  background: #ffffff;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}

img {
  width: 100%;
}

.page-header {
  font-size: 30px;
  font-weight: 700;
  border: none;
  padding-top: 50px;
  text-transform: uppercase;
  margin: 0 0 20px 0;
}
